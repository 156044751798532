import React from "react"
import { PageNotFound } from '../components/pageNotFound'


const NotFoundPage = () => (
 <>
 <PageNotFound/>
 </>
)

export default NotFoundPage
