import React from 'react'
import { device  } from '../../utils/breakpoints'
import styled from 'styled-components'
import { Heading } from '../../components/common/heading'
import { Wrapper } from '../../components/common/wrapper'


const WrapperPageNotFound = styled(Wrapper) `
    background: #fff;
    min-height: 70vh;

    @media ${device.desktop} { 
        padding: 5rem 8.5vw;
    }
    
    display: flex;
    justify-content: center;
    align-items: center;

`
const ContainerPageNotFound = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`

const ContainerHeadingPageNotFound = styled.div `
    margin-bottom: 3rem;
`

const ContainerParagraphPageNotFound = styled.div `
       font-size: 1rem;
    line-height: 1.5;
    color: #151515;

    

`



export const PageNotFound = () => {



    return (
        <WrapperPageNotFound>
            <ContainerPageNotFound>
                <ContainerHeadingPageNotFound>
                    <h1>404</h1>
                </ContainerHeadingPageNotFound>
                <ContainerParagraphPageNotFound>
                    <p>Page Not Found</p>
                </ContainerParagraphPageNotFound>
            </ContainerPageNotFound>
        </WrapperPageNotFound>
    )
}